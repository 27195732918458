import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //
interface Props {
    border?: boolean;
    boxShadow?: boolean;
    children: ReactNode;
    content?: boolean;
    contentClass?: string;
    contentSX?: any;
    darkTitle?: boolean;
    secondary?: ReactNode | string | any;
    shadow?: string;
    sx?: any;
    title?: ReactNode | string | any;
    [key: string]: any;
}

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow = '0 2px 14px 0 rgb(32 40 45 / 8%)',
            sx = {},
            title,
            ...others
        }: Props,
        ref: ForwardedRef<any>
    ) => {
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    // @ts-ignore // TODO: Find out how +75 works
                    borderColor: theme.palette.primary[200] + 75,
                    ':hover': {
                        boxShadow: boxShadow ? shadow : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
                {darkTitle && title && (
                    <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
                )}

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

export default MainCard;
