import { lazy } from 'react';

import MinimalLayout from '../../components/layout/MinimalLayout';
// project imports
import Loadable from '../../components/ui-component/Loadable';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('../../views/authentication/authentication/Login')));
// const AuthRegister = Loadable(lazy(() => import('../../views/authentication/authentication/Register')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        }
        // {
        //     path: '/register',
        //     element: <AuthRegister />
        // }
    ]
};

export default AuthenticationRoutes;
