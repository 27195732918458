// theme constant
export const GRID_SPACING = 3;
export const DRAWER_WIDTH = 260;
export const APP_DRAWER_WIDTH = 320;

export const DASHBOARD_ROOT = '/admin/';

export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT = 'DD/MM/YYYY';
