// assets
import { IconBriefcase, IconClipboardList, IconNotebook, IconUsers } from '@tabler/icons';

import { IMenuItem } from './IMenuItem';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const manageMenu: IMenuItem = {
    id: 'manage-menu',
    type: 'group',
    children: [
        {
            id: 'business',
            title: 'Business',
            type: 'item',
            url: '/admin/business',
            icon: IconBriefcase,
            breadcrumbs: true
        },
        {
            id: 'service',
            title: 'Services',
            type: 'item',
            url: '/admin/service',
            icon: IconClipboardList,
            breadcrumbs: true
        },
        {
            id: 'provider',
            title: 'Providers',
            type: 'item',
            url: '/admin/provider',
            icon: IconUsers,
            breadcrumbs: true
        },
        {
            id: 'unavailability',
            title: 'Unavailabilities',
            type: 'item',
            url: '/admin/unavailability',
            icon: IconNotebook,
            breadcrumbs: true
        }
    ]
};

export default manageMenu;
