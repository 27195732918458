// assets
import { IconChevronLeft } from '@tabler/icons';

import { IMenuItem } from './IMenuItem';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const backMenu: IMenuItem = {
    id: 'back',
    type: 'group',
    children: [
        {
            id: 'homepage',
            title: 'Go to homepage',
            type: 'item',
            url: '/{:businessSlug}',
            icon: IconChevronLeft,
            breadcrumbs: false
        }
    ]
};

export default backMenu;
