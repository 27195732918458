import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BackofficeState {
    businessSlug: string;
    businessId: string;
}

const initialState: BackofficeState = {
    businessId: '61545d154b317e4fccf33651', // TODO: Remove when API is fully migrated to businessSlug and/or multi-tenancy
    businessSlug: 'suwanmali' // TODO: Later empty when user service is ready
};

export const backofficeSlice = createSlice({
    name: 'backoffice',
    initialState,
    reducers: {
        setBusinessSlug: (state, action: PayloadAction<{ slug: string }>) => {
            state.businessSlug = action.payload.slug;
        }
    }
});

export const { setBusinessSlug } = backofficeSlice.actions;
export default backofficeSlice.reducer;
