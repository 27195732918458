import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyB0d8M-Fc-ZS5t6FWrNTb1t_Yv4HiD2VxA',
    authDomain: 'bookpad-app.firebaseapp.com',
    projectId: 'bookpad-app',
    storageBucket: 'bookpad-app.appspot.com',
    messagingSenderId: '31593298119',
    appId: '1:31593298119:web:2166783713371253d6e6e2',
    measurementId: 'G-6VVK0MV94B'
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics, app as firebaseApp };
