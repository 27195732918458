import { setCurrentScreen } from 'firebase/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { analytics } from '../modules/Firebase';

const useRouterTelemetry = () => {
    const location = useLocation();

    useEffect(() => {
        setCurrentScreen(analytics, location.pathname);
    }, [location]);
};

export default useRouterTelemetry;
