import { lazy } from 'react';

// project imports
import MainLayout from '../../components/layout/MainLayout';
import Loadable from '../../components/ui-component/Loadable';

// dashboard routing
// const DashboardPage = Loadable(lazy(() => import('../../views/business-admin/dashboard')));
const ProviderView = Loadable(lazy(() => import('../../views/business-admin/provider/ProviderView')));
const ProviderAdd = Loadable(lazy(() => import('../../views/business-admin/provider/ProviderAdd')));
const ProviderEdit = Loadable(lazy(() => import('../../views/business-admin/provider/ProviderEdit')));
const ProviderReorder = Loadable(lazy(() => import('../../views/business-admin/provider/ProviderReorder')));
const AppointmentView = Loadable(lazy(() => import('../../views/business-admin/appointment/AppointmentView')));
const AppointmentEdit = Loadable(lazy(() => import('../../views/business-admin/appointment/AppointmentEdit')));
const AppointmentAdd = Loadable(lazy(() => import('../../views/business-admin/appointment/AppointmentAdd')));
const UnavailabilityView = Loadable(lazy(() => import('../../views/business-admin/unavailability/UnavailabilityView')));
const UnavailabilityEdit = Loadable(lazy(() => import('../../views/business-admin/unavailability/UnavailabilityEdit')));
const UnavailabilityAdd = Loadable(lazy(() => import('../../views/business-admin/unavailability/UnavailabilityAdd')));
const ServiceView = Loadable(lazy(() => import('../../views/business-admin/service/ServiceView')));
const ServiceEdit = Loadable(lazy(() => import('../../views/business-admin/service/ServiceEdit')));
const ServiceAdd = Loadable(lazy(() => import('../../views/business-admin/service/ServiceAdd')));
const BusinessEdit = Loadable(lazy(() => import('../../views/business-admin/business/BusinessEdit')));

// ==============================|| MAIN ROUTING ||============================== //

const BusinessAdminRoutes = {
    path: '/admin',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <AppointmentView /> // TODO: Replace with dashboard when ready
        },
        {
            path: 'business',
            element: <BusinessEdit />
        },
        {
            path: 'service',
            element: <ServiceView />
        },
        {
            path: 'service/add',
            element: <ServiceAdd />
        },
        {
            path: 'service/:serviceId',
            element: <ServiceEdit />
        },
        {
            path: 'provider',
            element: <ProviderView />
        },
        {
            path: 'provider/add',
            element: <ProviderAdd />
        },
        {
            path: 'provider/:providerId',
            element: <ProviderEdit />
        },
        {
            path: 'provider/reorder',
            element: <ProviderReorder />
        },
        {
            path: 'appointment',
            element: <AppointmentView />
        },
        {
            path: 'appointment/add',
            element: <AppointmentAdd />
        },
        {
            path: 'appointment/:appointmentId',
            element: <AppointmentEdit />
        },
        {
            path: 'unavailability',
            element: <UnavailabilityView />
        },
        {
            path: 'unavailability/add',
            element: <UnavailabilityAdd />
        },
        {
            path: 'unavailability/:unavailabilityId',
            element: <UnavailabilityEdit />
        },
        {
            path: 'client',
            element: <>Client</>
        }
    ]
};

export default BusinessAdminRoutes;
