import { configureStore } from '@reduxjs/toolkit';

import backofficeReducer from './slice/backofficeSlice';
import customizationReducer from './slice/customizationSlice';

export const store = configureStore({
    reducer: {
        customization: customizationReducer,
        backoffice: backofficeReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
