import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import config from '../../config';

export interface CustomizationState {
    isOpen: string[]; // for active default menu
    fontFamily: string;
    borderRadius: number;
    opened: boolean;
}

const initialState: CustomizationState = {
    isOpen: [],
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true
};

export const customizationSlice = createSlice({
    name: 'customization',
    initialState,
    reducers: {
        openMenu: (state, action: PayloadAction<{ id: string }>) => {
            state.isOpen = [action.payload.id];
        },
        setMenu: (state, action: PayloadAction<{ opened: boolean }>) => {
            state.opened = action.payload.opened;
        },
        setFontFamily: (state, action: PayloadAction<{ fontFamily: string }>) => {
            state.fontFamily = action.payload.fontFamily;
        },
        setBorderRadius: (state, action: PayloadAction<{ borderRadius: number }>) => {
            state.borderRadius = action.payload.borderRadius;
        }
    }
});

export const { openMenu, setMenu, setFontFamily, setBorderRadius } = customizationSlice.actions;
export default customizationSlice.reducer;
