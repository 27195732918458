import { lazy } from 'react';

// project imports
import MinimalLayout from '../../components/layout/MinimalLayout';
import Loadable from '../../components/ui-component/Loadable';

const BusinessIndex = Loadable(lazy(() => import('../../views/business-client/BusinessIndex')));

// ==============================|| MAIN ROUTING ||============================== //

const BusinessClientRoutes = {
    path: '/:businessSlug/',
    element: <MinimalLayout />,
    children: [
        {
            path: '',
            element: <BusinessIndex />
        }
    ]
};

export default BusinessClientRoutes;
