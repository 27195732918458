import { LocalizationProvider } from '@mui/lab';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// project imports
import NavigationScroll from './components/layout/NavigationScroll';
import useRouterTelemetry from './hooks/useRouterTelemetry';
// routing
import Routes from './routes/router';
import { RootState } from './store';
// defaultTheme
import themes from './themes';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state: RootState) => state.customization);
    useRouterTelemetry();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <LocalizationProvider dateAdapter={AdapterDayJS}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
