import backMenu from './backMenu';
import { IMenuItem } from './IMenuItem';
// import dashboard from './dashboardMenu';
import manageMenu from './manageMenu';
import primaryMenu from './primaryMenu';

// ==============================|| MENU ITEMS ||============================== //

export interface INavigation {
    items: IMenuItem[];
}

const menuItems: INavigation = {
    items: [
        // dashboard, // TODO: Enable when dashboard is ready
        primaryMenu,
        manageMenu,
        backMenu
    ]
};

export default menuItems;
