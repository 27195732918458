import { useRoutes } from 'react-router-dom';

import config from '../../config';
import AuthenticationRoutes from './AuthenticationRoutes';
import BusinessAdminRoutes from './BusinessAdminRoutes';
import BusinessClientRoutes from './BusinessClientRoutes';
// routes
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, AuthenticationRoutes, BusinessClientRoutes, BusinessAdminRoutes], config.basename);
}
