// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from '../../assets/images/logo-dark.svg';
 * import logo from '../../assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

interface Props {
    width?: number;
    height?: number;
}

const Logo = ({ width = 92, height = 32 }: Props) => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Bookpad" width="100" />
         *
         */
        <svg width={width} height={height} viewBox="0 0 92 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={theme.palette.primary.main}
                d="M6.7,22.2H1.4V10h4.7c0.8,0,1.5,0.1,2.1,0.3c0.6,0.2,1,0.5,1.3,0.8c0.6,0.6,0.8,1.3,0.8,2.1c0,0.9-0.3,1.7-0.9,2.1
		c-0.2,0.2-0.4,0.3-0.5,0.3c-0.1,0-0.2,0.1-0.4,0.2c0.8,0.2,1.4,0.5,1.8,1s0.7,1.2,0.7,1.9c0,0.8-0.3,1.6-0.9,2.2
		C9.6,21.8,8.4,22.2,6.7,22.2z M3.5,15.1h2.6c1.5,0,2.2-0.5,2.2-1.5c0-0.6-0.2-1-0.5-1.2c-0.4-0.3-0.9-0.4-1.7-0.4H3.5V15.1z
		 M3.5,20.3h3.2c0.8,0,1.3-0.1,1.7-0.4S9,19.2,9,18.6c0-1.1-0.9-1.6-2.6-1.6H3.5V20.3z"
            />
            <path
                fill={theme.palette.primary.main}
                d="M23.8,20.5c-1.2,1.2-2.7,1.8-4.5,1.8s-3.3-0.6-4.5-1.8c-1.2-1.2-1.8-2.7-1.8-4.5s0.6-3.3,1.8-4.5c1.2-1.2,2.7-1.8,4.5-1.8
		s3.3,0.6,4.5,1.8s1.8,2.7,1.8,4.5S25,19.3,23.8,20.5z M22.3,12.9c-0.8-0.9-1.8-1.3-3-1.3s-2.2,0.4-3,1.3C15.4,13.7,15,14.8,15,16
		c0,1.2,0.4,2.3,1.2,3.1c0.8,0.9,1.8,1.3,3,1.3s2.2-0.4,3-1.3c0.8-0.9,1.2-1.9,1.2-3.1C23.6,14.8,23.1,13.7,22.3,12.9z"
            />
            <path
                fill={theme.palette.primary.main}
                d="M38.4,20.5c-1.2,1.2-2.7,1.8-4.5,1.8s-3.3-0.6-4.5-1.8c-1.2-1.2-1.8-2.7-1.8-4.5s0.6-3.3,1.8-4.5c1.2-1.2,2.7-1.8,4.5-1.8
		s3.3,0.6,4.5,1.8s1.8,2.7,1.8,4.5S39.6,19.3,38.4,20.5z M36.9,12.9c-0.8-0.9-1.8-1.3-3-1.3s-2.2,0.4-3,1.3
		c-0.8,0.9-1.2,1.9-1.2,3.1c0,1.2,0.4,2.3,1.2,3.1c0.8,0.9,1.8,1.3,3,1.3s2.2-0.4,3-1.3c0.8-0.9,1.2-1.9,1.2-3.1
		C38.1,14.8,37.7,13.7,36.9,12.9z"
            />
            <path fill={theme.palette.primary.main} d="M42.8,10h2v5.9l5.6-5.9H53l-4.9,5.3l4.9,6.8h-2.4l-4-5.3l-1.8,1.9v3.4h-2V10z" />
            <path
                fill={theme.palette.primary.main}
                d="M63.4,11.1c0.9,0.7,1.3,1.7,1.3,3c0,1.3-0.4,2.3-1.3,3c-0.9,0.7-2.1,1.1-3.6,1.1h-3.5v4h-0.9V10h4.4
		C61.3,10,62.5,10.4,63.4,11.1z M62.8,16.5c0.7-0.6,1-1.4,1-2.4c0-1.1-0.3-1.9-1-2.4c-0.7-0.6-1.7-0.9-3-0.9h-3.5v6.6h3.5
		C61.1,17.4,62.1,17.1,62.8,16.5z"
            />
            <path
                fill={theme.palette.primary.main}
                d="M74.6,18.7h-7L66,22.2h-1L70.6,10h0.9l5.6,12.1h-1L74.6,18.7z M74.3,18l-3.2-7l-3.2,7H74.3z"
            />
            <path
                fill={theme.palette.primary.main}
                d="M79.3,10h4.8c1.3,0,2.4,0.3,3.4,0.8s1.7,1.2,2.3,2.2c0.5,0.9,0.8,2,0.8,3.1c0,1.2-0.3,2.2-0.8,3.1
		c-0.5,0.9-1.3,1.6-2.3,2.2s-2.1,0.8-3.4,0.8h-4.8V10z M84.1,21.4c1.1,0,2.1-0.2,3-0.7c0.9-0.4,1.5-1.1,2-1.9
		c0.5-0.8,0.7-1.7,0.7-2.7c0-1-0.2-1.9-0.7-2.7c-0.5-0.8-1.1-1.4-2-1.9c-0.9-0.4-1.8-0.7-3-0.7h-3.9v10.5H84.1z"
            />
        </svg>
    );
};

export default Logo;
