// assets
import { IconNotebook } from '@tabler/icons';

import { IMenuItem } from './IMenuItem';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const primaryMenu: IMenuItem = {
    id: 'primary',
    type: 'group',
    children: [
        {
            id: 'appointment',
            title: 'Appointments',
            type: 'item',
            url: '/admin/appointment',
            icon: IconNotebook,
            breadcrumbs: true
        }
        // {
        //     id: 'client',
        //     title: 'Clients',
        //     type: 'item',
        //     url: '/admin/client',
        //     icon: IconUsers,
        //     breadcrumbs: true
        // }
    ]
};

export default primaryMenu;
