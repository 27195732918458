import { lazy } from 'react';

// project imports
import MinimalLayout from '../../components/layout/MinimalLayout';
import Loadable from '../../components/ui-component/Loadable';

const ComingSoon = Loadable(lazy(() => import('../../views/ComingSoon')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '',
            element: <ComingSoon />
        }
    ]
};

export default MainRoutes;
