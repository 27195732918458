import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
// material-ui
import { useTheme } from '@mui/material/styles';
// assets
import { IconTallymark1 } from '@tabler/icons';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// project imports
import config from '../../../config';
import { GRID_SPACING } from '../../../modules/Constant';
import { INavigation } from '../../../routes/menu-items';
import { IMenuItem } from '../../../routes/menu-items/IMenuItem';
import { RootState } from '../../../store';

const linkSX = {
    display: 'flex',
    color: 'grey.900',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center'
};

// ==============================|| BREADCRUMBS ||============================== //

interface Props {
    card?: boolean;
    divider?: boolean;
    icon?: boolean;
    icons?: boolean;
    maxItems?: number;
    navigation: INavigation;
    rightAlign?: boolean;
    separator: ((...args: any[]) => any) | any;
    title?: boolean;
    titleBottom?: boolean;
}

const Breadcrumbs = ({
    card,
    divider,
    // eslint-disable-next-line no-unused-vars
    icon,
    icons,
    maxItems = 8,
    navigation,
    rightAlign,
    separator,
    title,
    titleBottom,
    ...others
}: Props) => {
    const theme = useTheme();

    const iconStyle = {
        marginRight: theme.spacing(0.75),
        marginTop: `-${theme.spacing(0.25)}`,
        width: '1rem',
        height: '1rem',
        color: theme.palette.secondary.main
    };

    const [main, setMain] = useState<IMenuItem>();
    const [item, setItem] = useState<IMenuItem>();

    const backofficeState = useSelector((state: RootState) => state.backoffice);

    // set active item state
    const getCollapse = useCallback(
        (menu: IMenuItem) => {
            if (menu.children) {
                menu.children.filter((menuChildren) => {
                    if (menuChildren.type && menuChildren.type === 'collapse') {
                        getCollapse(menuChildren);
                    } else if (menuChildren.type && menuChildren.type === 'item') {
                        if (
                            document.location.pathname ===
                            config.basename + menuChildren.url?.replace('{:businessSlug}', backofficeState.businessSlug)
                        ) {
                            setMain(menu);
                            setItem(menuChildren);
                        }
                    }
                    return false;
                });
            }
        },
        [backofficeState]
    );

    useEffect(() => {
        navigation?.items?.map((menu) => {
            if (menu.type && menu.type === 'group') {
                getCollapse(menu);
            }
            return false;
        });
    });

    // item separator
    const SeparatorIcon = separator;
    const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size="1rem" /> : <IconTallymark1 stroke={1.5} size="1rem" />;

    let mainContent;
    let itemContent;
    let breadcrumbContent = <Typography />;
    let itemTitle = '';
    let CollapseIcon;
    let ItemIcon;

    // collapse item
    if (main && main.type === 'collapse') {
        CollapseIcon = main.icon ? main.icon : AccountTreeTwoToneIcon;
        mainContent = (
            <Typography component={Link} to="#" variant="subtitle1" sx={linkSX}>
                {icons && <CollapseIcon style={iconStyle} />}
                {main.title}
            </Typography>
        );
    }

    // items
    if (item && item.type === 'item') {
        itemTitle = item.title ?? '';

        ItemIcon = item.icon ? item.icon : AccountTreeTwoToneIcon;
        itemContent = (
            <Typography
                variant="subtitle1"
                sx={{
                    display: 'flex',
                    textDecoration: 'none',
                    alignContent: 'center',
                    alignItems: 'center',
                    color: 'grey.500'
                }}
            >
                {icons && <ItemIcon style={iconStyle} />}
                {itemTitle}
            </Typography>
        );

        // main
        if (item.breadcrumbs !== false) {
            breadcrumbContent = (
                <Card
                    sx={{
                        marginBottom: card === false ? 0 : theme.spacing(GRID_SPACING),
                        border: card === false ? 'none' : '1px solid',
                        // @ts-ignore // TODO: Find out how +75 works
                        borderColor: theme.palette.primary[200] + 75,
                        background: card === false ? 'transparent' : theme.palette.background.default
                    }}
                    {...others}
                >
                    <Box sx={{ p: 2, pl: card === false ? 0 : 2 }}>
                        <Grid
                            container
                            direction={rightAlign ? 'row' : 'column'}
                            justifyContent={rightAlign ? 'space-between' : 'flex-start'}
                            alignItems={rightAlign ? 'center' : 'flex-start'}
                            spacing={1}
                        >
                            {title && !titleBottom && (
                                <Grid item>
                                    <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                        {item.title}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <MuiBreadcrumbs
                                    sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
                                    aria-label="breadcrumb"
                                    maxItems={maxItems}
                                    separator={separatorIcon}
                                >
                                    {mainContent}
                                    {itemContent}
                                </MuiBreadcrumbs>
                            </Grid>
                            {title && titleBottom && (
                                <Grid item>
                                    <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                        {item.title}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    {card === false && divider !== false && <Divider sx={{ borderColor: theme.palette.primary.main, mb: GRID_SPACING }} />}
                </Card>
            );
        }
    }

    return breadcrumbContent;
};

export default Breadcrumbs;
